@use '@angular/material' as mat;
@use 'sass:map';
@import 'src/scss/utils/mixins';

/***
  * Button toggle style.
  * Used as a mix between button and chip
 */

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $button-toggle-border-radius: 1.5rem;

  .filter-toggle {
    @include gap-sm;
    border: none;

    &__button {
      @include semi-bold;
      @include primary-color($theme, 500);
      padding: 0 0.5rem;
      border-radius: $button-toggle-border-radius;
      border: 1px solid mat.get-color-from-palette($primary, 500) !important;
    }

    // Active variant
    .mat-button-toggle-checked {
      background-color: mat.get-color-from-palette($primary, 500) !important;
      color: mat.get-color-from-palette($primary, 100) !important;
    }

    .mat-button-toggle-label-content {
      @include text-sm;
      line-height: 2rem;
    }
  }

  /***
    * Large toggle button
   */
  .large-toggle-group {
    @include gap-sm;
    border: none;

    .mat-button-toggle-label-content {
      line-height: 2rem;
    }

    // Active variant
    .mat-button-toggle-checked {
      background-color: mat.get-color-from-palette($primary, 200) !important;

      .large-toggle {
        &__title,
        &__description {
          @include primary-color($theme, 800);
        }
      }
    }
  }

  .large-toggle {
    @include center;
    @include container-border-radius-xs;
    padding: 1rem;
    flex-direction: column;
    border: 1px solid mat.get-color-from-palette($primary, 500) !important;
    max-width: 22rem;
    cursor: pointer;

    &:hover &__title,
    &:hover &__description {
      @include primary-color($theme, 800);
    }

    &__title {
      @include primary-color($theme, 500);
      @include gap-sm;
      display: flex;
      align-items: center;
    }

    &__icon {
      width: 1.25rem;
      height: 1.25rem;
    }

    &__description {
      @include grey-color(500);
      @include text-xs;
      text-align: start;

      span {
        white-space: normal;
        line-height: normal;
      }
    }
  }

  // Common ripple effect
  .mat-ripple {
    border-radius: $button-toggle-border-radius;
  }
}
