@import 'src/scss/utils/mixins';

.modal-no-padding {
  .mat-mdc-dialog-surface {
    padding: 0 !important;
  }
}

// Override default modals
.mat-mdc-dialog-surface {
  @include shadow-1;
  border-radius: 1.4rem !important;
  padding: 1.8em;
  overflow-y: hidden !important;
}

.modal-actions,
.mat-mdc-dialog-actions {
  @include gap;
  @include vertical-center;
  justify-content: flex-end !important;
  margin-top: 1rem;
}

.mat-mdc-dialog-content {
  // Leave a space to the right side for the scroll bar
  padding: 0 0.6rem 0 0 !important;
}
