/* You can add global styles to this file, and also import other style files */

@use 'palette.scss' as pal;
@use '@angular/material' as mat;
@use 'sass:map';
@use 'utils/mixins' as mix;

@import 'components/buttons';
@import 'components/forms';
@import 'components/chips';
@import 'components/tables';
@import 'components/displays';
@import 'components/select';
@import 'components/cards';
@import 'components/modals';
@import 'components/snackbar';
@import 'components/paginator';
@import 'components/admonitions';
@import 'components/progress-bar';
@import 'components/skeletons';
@import 'components/accordion';
@import 'components/toggle-buttons';

// Sliders styles
@import '../../node_modules/swiper/swiper.scss';
@import '../../node_modules/swiper/modules/navigation/navigation.scss';

@mixin theme($theme, $title-typography) {
  $primary: map.get($theme, primary);

  html,
  body {
    @include mix.grey-color(800); // Default text color
    height: 100%;
    margin: 0;
  }

  /**
   * Tooltips
   */
  .mdc-tooltip .mdc-tooltip__surface {
    @include mix.shadow-1;
    @include mix.primary-color($theme, 100);
    @include mix.primary-background($theme, 800);
    border-radius: 0.5rem !important;
    font-weight: 500;
    padding: 0.7rem 1rem !important;
    font-size: 0.8rem;
  }

  /**
   * <hr/> dividers
   */
  .divider {
    flex: 1;
    width: -webkit-fill-available; // Applied when flex is not available
    border-left: none; // Remove small pixel from the left side
    border-top: 1px solid mat.get-color-from-palette(pal.$app-grey, 300);
  }

  /**
   * Override of the accordion expansion arrow from the Course Detail component,
   * it has to be in this file for the override to be successful
   */
  .mat-expansion-indicator::after {
    @include mix.primary-color($theme, 800);
  }

  /**
   * Categories and competences (secondary categories)
   */
  .category {
    @include mix.row;
    @include mix.center;
    @include mix.gap-sm;
    @include mix.primary-background($theme, 50);
    @include mix.primary-color($theme, 800);
    @include mix.text-sm;
    text-decoration: none;
    align-items: center;
    padding: 0.4rem 1.5rem;
    border-radius: 1.25rem;
    transition: all 0.2s;

    // Enable pointer/hover events when categories are searchable
    /**
    cursor: pointer;
    &:hover {
      @include primary-color(500);
    }

    &:active:not(:disabled) {
      @include primary-color(800);
    }
    */

    &--secondary {
      @include mix.secondary-background-gradient($theme, 100);

      /* Enable pointer/hover events when categories are searchable
      &:hover {
        @include secondary-color(700);
      }
      */
    }

    &--slider {
      @include mix.container-border-radius-xs();
      background: mat.get-color-from-palette(pal.$app-grey, 100);
      padding: 0.5rem 1rem;

      p {
        @include mix.hide-text-with-ellipsis(2rem);
        text-align: center;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  //Removes tooltip from ionic icons
  ion-icon {
    pointer-events: none;
  }

  .disabled-clicks {
    pointer-events: none;
  }
}

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10000;
}
