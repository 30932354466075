@use '@angular/material' as mat;
@use 'sass:map';
@use 'src/scss/palette' as pal;
@import 'src/scss/utils/mixins';

/***
  Apply custom styles to the default Angular Material snackbar
 */

@mixin theme($theme) {
  $primary: map.get($theme, primary);

  .mdc-snackbar .mdc-snackbar__surface {
    @include shadow-1;
    border-radius: 1rem !important;
    background-color: mat.get-color-from-palette($primary, 100);
    margin-bottom: 3.5rem;

    // Snackbar text
    .mdc-snackbar__label {
      padding: 1rem 1.8rem !important;

      .toast-content {
        @include vertical-center;
        @include gap;
        font-weight: 500;
        font-size: 0.9rem;

        .toast-icon {
          font-size: 1.5rem;
        }
      }
    }
  }

  /*
    Info snackbar (by default)
   */
  .mat-snack-bar-info .mdc-snackbar__surface {
    background-color: mat.get-color-from-palette($primary, 100) !important;

    .mdc-snackbar__label {
      @include grey-color(800);

      .toast-icon {
        color: mat.get-color-from-palette($primary, 800);
      }
    }
  }

  /*
    Error snackbar
   */
  .mat-snack-bar-error .mdc-snackbar__surface {
    background-color: mat.get-color-from-palette(pal.$app-error, 300) !important;

    .mdc-snackbar__label {
      @include semi-bold;
      color: mat.get-color-from-palette(pal.$app-error, 800);
    }
  }

  /*
    Success snackbar
   */
  .mat-snack-bar-success .mdc-snackbar__surface {
    background-color: mat.get-color-from-palette(pal.$app-success, 300) !important;

    .mdc-snackbar__label {
      @include grey-color(800);

      .toast-icon {
        color: mat.get-color-from-palette(pal.$app-success, 500);
      }
    }
  }
}
