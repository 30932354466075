@use '@angular/material' as mat;
@use 'src/scss/palette' as pal;
@use 'src/scss/utils/mixins' as mix;

.interactive-card {
  cursor: pointer;

  &:hover {
    .image--cover {
      opacity: 0.7;
    }
  }
}

.container-logo {
  max-width: 80%;
  width: fit-content;
  background: white;
  opacity: 80%;
  height: 3.625rem;
  border-top-right-radius: 1rem;

  @include mix.center;

  .logo {
    height: 3.625rem;
    max-width: 100%;
    object-fit: contain;
    border-radius: inherit;
  }
}

.item-card {
  @include mix.row;
  @include mix.gap;
  @include mix.vertical-center;
  cursor: pointer;

  &:hover {
    .image {
      &--cover {
        opacity: 0.7;
      }
    }
  }

  .image {
    @include mix.column;

    height: 8.5rem;
    min-height: 8.5rem;
    min-width: 14.5rem;
    width: 14.5rem;
    position: relative;

    &--course {
      height: 7.5rem;
      min-height: 7.5rem;
      min-width: 12.5rem;
      width: 12.5rem;
    }

    &--cover {
      @include mix.fill-vertical-horizontal;
      @include mix.container-border-radius-sm;
      object-fit: cover;
      flex: 1;
      display: flex;
      align-items: flex-end;
    }

    &--icon {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      color: white;
      width: 2.7rem;
      height: 2.7rem;

      &__course {
        bottom: 0.5rem;
        left: 0.5rem;
        width: 2.25rem;
        height: 2.25rem;

        // To make sure the icon has adequate contrast for visibility
        filter: drop-shadow(1px 1px 0.625rem rgba(0, 0, 0, 0.4));
      }
    }
  }

  .details {
    @include mix.column;
    @include mix.gap-sm;
    flex-grow: 1;

    &__title {
      @include mix.vertical-center;
      @include mix.grey-color(800);
      @include mix.hide-text-with-ellipsis($lines_number: 1);
      line-break: anywhere;
    }

    &__icon {
      height: 1.5rem;
      min-width: 1.5rem;
    }

    &__description {
      @include mix.hide-text-with-ellipsis(3.7rem, $lines-number: 3);
      @include mix.grey-color(500);
      @include mix.text-sm;
    }

    &--skeleton {
      width: 20rem;
    }
  }
}
