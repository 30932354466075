@use '@angular/material' as mat;
@use 'src/scss/palette' as pal;
@use 'sass:map';
@import 'screen';

@mixin stylize-text($font-size, $font-weight, $line-height) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

/***
  Flex shortcuts
 */
@mixin column() {
  display: flex;
  flex-direction: column;
}

@mixin column-reverse() {
  display: flex;
  flex-direction: column-reverse;
}

@mixin row() {
  display: flex;
  flex-direction: row;
}

@mixin row-reverse() {
  display: flex;
  flex-direction: row-reverse;
}

@mixin fill-vertical-horizontal() {
  width: 100%;
  height: 100%;
}

// Vertical and horizontal centering
@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin horizontal-start() {
  display: flex;
  justify-content: flex-start;
}

@mixin horizontal-end() {
  display: flex;
  justify-content: flex-end;
}

@mixin horizontal-center() {
  display: flex;
  justify-content: center;
}

@mixin vertical-center() {
  display: flex;
  align-items: center;
}

@mixin vertical-end() {
  display: flex;
  align-items: flex-end;
}

@mixin row-wrap() {
  @include row;
  flex-wrap: wrap;
}

@mixin no-wrap() {
  flex-wrap: nowrap;
}

/***
  Gap shortcuts
  Used to standardize the use of given gaps in the app according to the standardized design
 */
@mixin gap-xxl() {
  gap: 2.5rem;
}

@mixin gap-xl() {
  gap: 2rem;
}

@mixin gap-lg() {
  gap: 1.5rem;
}

@mixin gap() {
  gap: 1rem;
}

@mixin gap-md() {
  gap: 0.625rem;
}

@mixin gap-sm() {
  gap: 0.5rem;
}

@mixin gap-xs() {
  gap: 0.25rem;
}

/***
  * Padding shortcuts
  * Used to standardize the use of given paddings in the app according to the standardized design
  * In most cases, you achieve design guidelines by using these shortcuts
 */

@mixin padding-xs() {
  padding: 0.5rem; // Equivalent to 8px
}

@mixin padding-sm() {
  padding: 1rem; // Equivalent to 16px
}

@mixin padding() {
  padding: 2rem; // Equivalent to 32px
}

@mixin padding-lg() {
  padding: 3rem; // Equivalent to 48px
}

@mixin horizontal-padding-sm() {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
/***
  * Standard container padding
  * Applies certain paddings to the container according to the screen size
 */
@mixin default-container-padding($vertical-padding: 2rem) {
  padding: $vertical-padding 2rem;

  @include xxl {
    padding: $vertical-padding 25rem;
  }

  @include xl {
    padding: $vertical-padding 10rem;
  }

  @include lg {
    padding: $vertical-padding 5rem;
  }

  @include sm {
    padding: $vertical-padding 1rem;
  }

  @include xs {
    padding: $vertical-padding 0.5rem;
  }
}

/***
  * Standard container padding
  * Applies certain paddings to the container according to the screen size
 */
@mixin large-padding-container($vertical-padding: 4.5rem) {
  padding: $vertical-padding 2rem;

  @include xxl {
    padding: $vertical-padding 27.5rem;
  }

  @include xl {
    padding: $vertical-padding 22.5rem;
  }

  @include lg {
    padding: $vertical-padding 17.5rem;
  }

  @include sm {
    padding: $vertical-padding 10rem;
  }

  @include xs {
    padding: $vertical-padding 5rem;
  }
}

/***
  * Standard container margin
  * Applies certain margins to the container according to the screen size
 */
@mixin default-container-margin($vertical-margin: 2rem) {
  margin: $vertical-margin 2rem;

  @include xxl {
    margin: $vertical-margin 25rem;
  }

  @include xl {
    margin: $vertical-margin 10rem;
  }

  @include lg {
    margin: $vertical-margin 5rem;
  }

  @include sm {
    margin: $vertical-margin 1rem;
  }

  @include xs {
    margin: $vertical-margin 0.5rem;
  }
}

/***
  * Standard container border radius
  * Created to reuse the same border in most of the containers
 */

// Used in bigger containers
@mixin container-border-radius {
  border-radius: 2rem;
}

// Used in smaller components, e.g. small info cards
@mixin container-border-radius-sm {
  border-radius: 1rem !important;
}

// Used in smallest components, e.g. small items in paginator
@mixin container-border-radius-xs {
  border-radius: 0.5rem !important;
}

/***
  * Reuse the same shadows in most of the components
 */
@mixin shadow-1() {
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.08) !important;
}

@mixin shadow-2() {
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.17);
}

/***
  * Font utils
  * Main advantage is being used directly in the HTML without the need
  * to create a class only for apply font styles.
 */

@mixin bold() {
  font-family: var(--custom-font-bold);
  font-weight: 700;
}

@mixin semi-bold() {
  font-family: var(--custom-font-semi-bold);
  font-weight: 600;
}

@mixin medium() {
  font-weight: 500;
}

@mixin normal-weight() {
  font-weight: normal;
}

@mixin text-lg() {
  font-size: 1.125rem;
  line-height: 1.4rem;
}

@mixin text-md() {
  font-size: 1rem;
  line-height: 1.25rem;
}

@mixin text-sm() {
  font-size: 0.875rem;
  line-height: 1.05rem;
}

@mixin text-xs() {
  font-size: 0.75rem;
  line-height: 0.9rem;
}

/***
  * Palette utils
  * Avoid using mat functions when there are simpler attributes to use
 */

@mixin primary-color($theme, $color: 800) {
  $primary: map.get($theme, primary);
  color: mat.get-color-from-palette($primary, $color);
}

@mixin secondary-color($theme, $color: 500) {
  $secondary: map.get($theme, accent);
  color: mat.get-color-from-palette($secondary, $color);
}

@mixin grey-color($color: 500) {
  color: mat.get-color-from-palette(pal.$grey-palette, $color);
}

@mixin error-color($color: 800) {
  color: mat.get-color-from-palette(pal.$error-palette, $color);
}

@mixin success-color($color: 500) {
  color: mat.get-color-from-palette(pal.$success-palette, $color);
}

@mixin warn-color($color: 500) {
  color: mat.get-color-from-palette(pal.$warn-palette, $color);
}

@mixin mylxp-text-color() {
  background: -webkit-linear-gradient(0deg, pal.$mylxp-gradient-left-color, pal.$mylxp-gradient-right-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin primary-background($theme, $color: 100) {
  $primary: map.get($theme, primary);
  background-color: mat.get-color-from-palette($primary, $color);
}

@mixin primary-background-gradient($theme, $color: 50, $start: #fff) {
  $primary: map.get($theme, primary);
  background: linear-gradient(92deg, $start, mat.get-color-from-palette($primary, $color));
}

@mixin secondary-background($theme, $color: 500) {
  $secondary: map.get($theme, accent);
  background-color: mat.get-color-from-palette($secondary, $color);
}

@mixin grey-background($color: 100) {
  background-color: mat.get-color-from-palette(pal.$grey-palette, $color);
}

@mixin warn-background($color: 100) {
  background-color: mat.get-color-from-palette(pal.$warn-palette, $color);
}

@mixin primary-background-gradient($theme, $color: 50, $start: #fff) {
  $primary: map.get($theme, primary);
  background: linear-gradient(92deg, $start, mat.get-color-from-palette($primary, $color));
}

@mixin secondary-background-gradient($theme, $color: 50, $start: #fff) {
  $secondary: map.get($theme, accent);
  background: linear-gradient(92deg, $start, mat.get-color-from-palette($secondary, $color));
}

@mixin grey-border($border: 1px, $color: 100) {
  border: $border solid mat.get-color-from-palette(pal.$grey-palette, $color);
}

@mixin primary-border($theme, $border: 1px, $color: 100) {
  $primary: map.get($theme, primary);
  border: $border solid mat.get-color-from-palette($primary, $color);
}

/***
  * Table helper mixins
 */

/*
  Used to apply styles to a specific NG dynamic table column
 */
@mixin ng-table-column($column-name) {
  :host ::ng-deep {
    .mat-mdc-cell,
    .mat-mdc-header-cell {
      &.mat-column-#{$column-name} {
        @content;
      }
    }
  }
}

/**
  Used to put ellipsis in an text if is bigger than $lines_number lines
 */
@mixin hide-text-with-ellipsis($height: 1.5rem, $lines_number: 2) {
  display: -webkit-box;
  max-height: $height;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines_number;
}

@mixin saving-changes {
  pointer-events: none !important;
  opacity: 0.7;
}

@mixin spinner() {
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin justify-content-between() {
  justify-content: space-between;
}

@mixin no-ripple() {
  --mat-mdc-button-ripple-color: transparent;
  --mat-mdc-button-persistent-ripple-color: transparent;
}
