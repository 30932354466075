/***
  * Inside this file there are all the HTML shortcut classes to reduce
  * the amount of additional CSS classes. It's preferable not to combine these
  * classes if there are an existing class created to easily overview the styles
  * of a element without having to look both in the HTML and CSS files.
  *
  * Example:
  *   <div class="row vertical-center horizontal-end gap">...</div>
 */

@use 'src/scss/palette' as pal;
@use 'sass:map';
@import 'src/scss/utils/mixins';

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $secondary: map.get($theme, accent);

  .column {
    @include column;
  }

  .column-sm {
    @include sm {
      flex-direction: column !important;
    }
  }

  .column-reverse {
    @include column-reverse;
  }

  .row {
    @include row;
  }

  .row-sm {
    @include sm {
      flex-direction: row !important;
    }
  }
  .row-reverse {
    @include row-reverse;
  }

  .row-wrap {
    @include row-wrap;
  }

  .no-wrap {
    @include no-wrap;
  }

  .gap-xxl {
    @include gap-xxl;
  }

  .gap-xl {
    @include gap-xl;
  }

  .gap-lg {
    @include gap-lg;
  }

  .gap {
    @include gap;
  }

  .gap-md {
    @include gap-md;
  }

  .gap-sm {
    @include gap-sm;
  }

  .gap-xs {
    @include gap-xs;
  }

  .center {
    @include center;
  }

  .fill {
    width: 100%;
  }

  fill-horizontal-80 {
    width: 80%;
  }

  .fill-vertical-horizontal {
    width: 100%;
    height: 100%;
  }

  .fill-vertical {
    height: 100%;
  }

  .padding-xs {
    @include padding-xs;
  }

  .padding {
    @include padding;
  }

  .padding-sm {
    @include padding-sm;
  }

  .horizontal-padding-sm {
    @include horizontal-padding-sm;
  }

  .hide-sm {
    @include sm {
      display: none !important;
    }
  }

  .container-border-radius-sm {
    @include container-border-radius-sm;
  }

  /***
    * Alignments
   */

  .horizontal-start {
    @include horizontal-start;
  }

  .horizontal-start-sm {
    @include sm {
      justify-content: flex-start !important;
    }
  }

  .justify-content-between {
    @include justify-content-between;
  }

  .horizontal-center {
    @include horizontal-center;
  }

  .horizontal-end {
    @include horizontal-end;
  }

  .vertical-end {
    @include vertical-end;
  }

  .vertical-center {
    @include vertical-center;
  }

  .text-align {
    &-center {
      text-align: center;
    }

    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }
  }

  .grow {
    display: flex;
    flex-grow: 1;
  }

  .no-shrink {
    flex-shrink: 0;
  }

  .no-margin {
    margin: 0 !important;
  }

  .no-padding {
    padding: 0 !important;
  }

  .pointer-cursor {
    cursor: pointer;
  }

  /***
    * Font displays
   */
  .bold {
    @include bold;
  }

  .semi-bold {
    @include semi-bold;
  }

  .medium {
    @include medium;
  }

  .normal-weight {
    @include normal-weight;
  }

  .font-size-2 {
    font-size: 1.2em;
  }

  .font-size-5 {
    font-size: 1.5em;
  }

  .text-xs {
    @include text-xs;
  }

  .text-sm {
    @include text-sm;
  }

  .text-md {
    @include text-md;
  }

  .text-lg {
    @include text-lg;
  }

  .icon-sm {
    font-size: 1.25rem;
  }

  .icon-md {
    font-size: 1.5rem;
  }

  .icon-lg {
    font-size: 2rem;
  }

  .icon-xl {
    font-size: 2.7rem;
  }

  /***
    * Palette utils
   */
  .primary-color-50 {
    @include primary-color($theme, 50);
  }

  .primary-color-200 {
    @include primary-color($theme, 200);
  }

  .primary-color-400 {
    @include primary-color($theme, 400);
  }

  .primary-color-500 {
    @include primary-color($theme, 500);
  }

  .primary-color-200 {
    @include primary-color($theme, 200);
  }

  .primary-color-800 {
    @include primary-color($theme, 800);
  }

  .primary-color-900 {
    @include primary-color($theme, 900);
  }

  .primary-background-50 {
    @include primary-background($theme, 50);
  }

  .primary-background-800 {
    @include primary-background($theme, 800);
  }

  .primary-background-gradient-50 {
    @include primary-background-gradient($theme);
  }

  .primary-background-gradient-100 {
    @include primary-background-gradient($theme, 100);
  }

  .primary-border-800 {
    @include primary-border($theme, 1px, 800);
  }

  .secondary-color-700 {
    @include secondary-color($theme, 700);
  }

  .secondary-color-800 {
    @include secondary-color($theme, 800);
  }

  .grey-color-50 {
    @include grey-color(50);
  }

  .grey-color-300 {
    @include grey-color(300);
  }

  .grey-color-400 {
    @include grey-color(400);
  }

  .grey-color-500 {
    @include grey-color(500);
  }

  .grey-color-700 {
    @include grey-color(700);
  }

  .grey-color-800 {
    @include grey-color(800);
  }

  .grey-background-50 {
    @include grey-background(50);
  }

  .grey-background-100 {
    @include grey-background(100);
  }

  .grey-background-200 {
    @include grey-background(200);
  }

  .grey-border-300 {
    @include grey-border(1px, 300);
  }

  .warn-color-800 {
    @include warn-color(800);
  }

  .warn-color-500 {
    @include warn-color(500);
  }

  .error-color-300 {
    @include error-color(300);
  }

  .error-color-500 {
    @include error-color(500);
  }

  .black-color {
    color: black !important;
  }

  .white-color {
    color: white !important;
  }

  .background-white {
    background-color: white;
  }

  .background-grey-100 {
    @include grey-background();
  }

  .background-grey-50 {
    @include grey-background(50);
  }

  .mylxp-text-color {
    @include mylxp-text-color();
  }

  .shadow-1 {
    @include shadow-1;
  }

  .container-border-radius {
    @include container-border-radius;
  }

  .container-border-radius-sm {
    @include container-border-radius-sm;
  }

  .container-border-radius-xs {
    @include container-border-radius-xs;
  }

  .spinner {
    @include spinner;
  }

  .margin-top {
    margin-top: 1rem;
  }

  .margin-bottom {
    margin-bottom: 1rem;
  }

  .margin-right {
    margin-right: 1rem;
  }

  .saving-changes {
    @include saving-changes;
  }

  .pre-wrap {
    white-space: pre-wrap;
  }

  .fit-content {
    width: fit-content;
  }

  .transparent-background {
    background-color: transparent !important;
  }

  .disabled-element {
    pointer-events: none;
    cursor: default;
    opacity: 0.8;
  }

  .underline-text {
    text-decoration: underline;
  }
}
