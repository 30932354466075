@use '@angular/material' as mat;
@use 'sass:map';
@use 'src/scss/palette' as pal;
@import 'src/scss/utils/mixins';

/**
  * Main button style
 */
@mixin theme($theme) {
  $primary: map.get($theme, primary);

  %button {
    border-radius: 30px !important;
    & > * {
      border-radius: 30px !important;
    }
    font-weight: 600;
    padding: 1.2rem 1.2rem !important;
    font-size: 0.95rem;
    gap: 0.5rem;

    // Align buttons icons
    .mdc-button__label {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.6rem;

      ion-icon {
        font-size: 1.3em;
      }
    }
  }

  .btn,
  .mdc-button,
  .mat-mdc-button {
    @extend %button;

    // Disabled buttons
    &:disabled,
    .mat-button-disabled,
    &--disabled {
      opacity: 0.5;
      cursor: default !important;
    }

    &.mat-primary,
    &.btn--primary {
      background: mat.get-color-from-palette($primary, 800) !important;
      color: mat.get-contrast-color-from-palette($primary, 800) !important;
    }

    &.btn--primary--white {
      background: mat.get-color-from-palette($primary, 500) !important;
      color: pal.$white !important;
    }

    &.mat-secondary,
    &.btn--secondary {
      @include grey-background(50);
      color: mat.get-color-from-palette(pal.$grey-palette, 500) !important;
      border: 1px solid mat.get-color-from-palette(pal.$grey-palette, 300) !important;
    }

    &.mat-tertiary,
    &.btn--tertiary {
      @include grey-background(50);
      $primary-color: mat.get-color-from-palette($primary, 800);
      color: $primary-color !important;
      border: 1px solid $primary-color !important;

      &:hover:enabled {
        @include grey-background(100);
      }

      &:active:enabled {
        @include grey-background(50);
      }
    }

    &.btn--danger {
      color: white !important;
      background: #b0263e !important;
      border: 0 !important;
    }

    &.mat-error,
    &.btn--destructive {
      background-color: mat.get-color-from-palette(pal.$error-palette, 500);
      color: mat.get-contrast-color-from-palette(pal.$error-palette, 500) !important;

      &:hover:enabled {
        background-color: mat.get-color-from-palette(pal.$error-palette, 600);
      }
    }

    &.btn--dropdown {
      @include text-sm;
      border: 1px solid mat.get-color-from-palette($primary, 800);
      color: mat.get-color-from-palette($primary, 800) !important;
      background: white;
    }

    /**
    * Button sizes: large, small
    */
    &.btn--lg {
      height: 2.8rem;
      padding: 0 1.25rem !important;
      min-width: 9rem;
    }

    &.btn--sm {
      height: 2rem;
      padding: 0 0.6rem !important;
    }

    /**
      Extend button to fill the container
    */
    &.btn--block {
      width: 100%;
    }
  }

  .text-btn {
    @include row;
    @include semi-bold;
    @include gap-sm;
    @include primary-color($theme, 800);
    align-items: center;
    padding: 0.5rem 0;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    text-decoration: none;

    &:hover {
      @include primary-color($theme, 500);
    }

    &--secondary {
      @include grey-color(500);

      &:hover {
        @include grey-color(700);
      }
    }

    &--white {
      color: pal.$white;
      &:hover {
        @include grey-color(300);
      }
    }

    &--danger {
      @include error-color(500);

      &:hover {
        @include error-color(800);
      }
    }
  }

  .dots-button {
    @include grey-color(800);
    font-size: 1.4rem;
    cursor: pointer;

    &:hover {
      @include grey-color(500);
    }
  }

  .disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.8;
  }
}

/**
  * Button with icon
 */
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple,
.mat-mdc-icon-button .mat-mdc-button-ripple {
  @include container-border-radius-xs();

  width: 3rem;
  height: 3rem;

  top: 50% !important;
  left: 50% !important;

  transform: translate(-50%, -50%) !important;
}

/*
 Hovers effects
 */

.mat-mdc-button-ripple {
  &:hover {
    @include grey-background(800);
  }
}

.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.1;
}

.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.2;
}

.menu-items-spinner {
  background-color: white;
  border: 0;
}
